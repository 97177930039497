<div class="content-holder">
  <div class="title-holder">
    <h3 class="page-title">{{ empName }} Evaluation Form</h3>
    <div class="year-dropdown">
      <p-dropdown [options]="year" [(ngModel)]="formYear" (onChange) = "yearChanged($event)"></p-dropdown>
    </div>
  </div>
  <div class="accordian-holder">
    <p-accordion [activeIndex]="index">
      <p-accordionTab [selected]="selected">
        <p-header>
          Basic Details
          <i [ngClass]="!basicDetailForm.invalid && 'pi pi-check'"></i>
        </p-header>
        <div class="form-holder">
          <form [formGroup]="basicDetailForm">
            <div class="form-group">
              <h3 class="required">Employee Name:</h3>
              <input 
              type="text" 
              placeholder="Your Answer" 
              formControlName="employeeName"
              />
            </div>
            <div class="form-group">
              <h3 class="required">Position:</h3>
              <input 
              type="text" 
              placeholder="Your Answer" 
              formControlName="position"
              >
            </div>
            <div class="form-group">
              <h3 class="required">Direct Supervisor(s):</h3>
              <input 
              type="text" 
              placeholder="Supervisor" 
              formControlName="directSupervisor"
              />
            </div>
            <div class="form-group">
              <h3 class="required">Department:</h3>
              <p-dropdown [options]="departments" formControlName="EmpDepartment"></p-dropdown>
            </div>
            <div class="form-group">
              <h3 class="required">Work Location and Client:</h3>
              <input 
              type="text" 
              placeholder="Location & Client" 
              formControlName="workLocation"
              />
            </div>
            <div class="form-group">
              <h3 class="required">Provide Key Accomplishments During the Evaluation Period (min 3, max 5)</h3>
            </div>
            <div formArrayName="keyAcomps" *ngFor="let _ of keyAcomps.controls; index as i">
              <div class="form-group">
                <input class="form-control" [formControlName]="i" placeholder="Key Accomplishment {{i + 1}}">
                <a *ngIf="i > 2 && !isSubmitted" class="delete-form-field" (click)="removeKeyAcomps(i)">x</a>
              </div>
            </div>
            <div *ngIf="!isSubmitted" class="form-group add-more d-flex justify-content-end">
              <a (click)="addKeyAcomps()" >Add More</a>
            </div>
            <div class="form-group mb-0">
              <button class="btn btn-primary" (click)="saveBasicDetailForm(); onFormSubmit()" [disabled]='!basicDetailForm.valid || isSubmitted'>Save</button>
            </div>
          </form>
        </div>
      </p-accordionTab>
      <p-accordionTab>
        <p-header>
          MobileLive Values-Based KPIs
          <i [ngClass]="!valueBasedKPI.invalid && 'pi pi-check'"></i>
        </p-header>
        <div class="form-holder">
          <div class="guidance">
            <button class="info-btn" type="button" (click)="showDialog()" pButton label="Show">
              <strong>Rating Guidance</strong> 
              <span class="pi pi-question"></span>
            </button>
          </div>
          <form [formGroup]="valueBasedKPI">
            <div class="form-group">
              <h3 class="required">Empathetic</h3>
              <label>We seek to understand before we solve and succeed</label>
              <p-dropdown (onChange)="onReasonChange($event)" [options]="evaluationOptions" formControlName="empathetic"></p-dropdown>
              <input class="form-control mt-2" formControlName="empathetic_reason" placeholder="Type your reason" [hidden]="!empatheticReason">
            </div>
            <div class="form-group">
              <h3 class="required">Innovative</h3>
              <label>We don’t just live in the future, we invent it</label>
              <p-dropdown (onChange)="onReasonChange($event)" [options]="evaluationOptions" formControlName="innovative"></p-dropdown>
              <input class="form-control mt-2" formControlName="innovative_reason" placeholder="Type your reason" [hidden]="!innovativeReason">
            </div>
            <div class="form-group">
              <h3 class="required">Persevering</h3>
              <label>We start with tenacity and end with devotion</label>
              <p-dropdown (onChange)="onReasonChange($event)" [options]="evaluationOptions" formControlName="persevering"></p-dropdown>
              <input class="form-control mt-2" formControlName="persevering_reason" placeholder="Type your reason" [hidden]="!perseveringReason">
            </div>
            <div class="form-group">
              <h3 class="required">Reliable</h3>
              <label>We deliver what we promise</label>
              <p-dropdown (onChange)="onReasonChange($event)" [options]="evaluationOptions" formControlName="reliable"></p-dropdown>
              <input class="form-control mt-2" formControlName="reliable_reason" placeholder="Type your reason" [hidden]="!reliableReason">
            </div>
            <div class="form-group">
              <h3 class="required">Inclusive</h3>
              <label>We are stronger together than apart</label>
              <p-dropdown (onChange)="onReasonChange($event)" [options]="evaluationOptions" formControlName="inclusive"></p-dropdown>
              <input class="form-control mt-2" formControlName="inclusive_reason" placeholder="Type your reason" [hidden]="!inclusiveReason">
            </div>
            <div class="form-group mb-0">
              <button class="btn btn-primary" (click)="saveValueBasedKPIFrom(); onFormSubmit()" [disabled]='!valueBasedKPI.valid || isSubmitted'>Save</button>
            </div>
          </form>
        </div>
      </p-accordionTab>
      <p-accordionTab>
        <p-header>
          Performance Based KPIs
          <i [ngClass]="!performanceBasedKPI.invalid && 'pi pi-check'"></i>
        </p-header>
        <div class="form-holder">
          <form [formGroup]="performanceBasedKPI">
            <div class="form-group">
              <h3 class="required">Quality of Work</h3>
              <label>Consider task accuracy, completeness and thoroughness</label>
              <p-dropdown (onChange)="onReasonChange($event)" [options]="evaluationOptions" formControlName="qualityOfWork"></p-dropdown>
              <input class="form-control mt-2" formControlName="qualityOfWork_reason" placeholder="Type your reason" [hidden]="!qualityOfWorkReason">
            </div>
            <div class="form-group">
              <h3 class="required">Knowledge of Job</h3>
              <label>Consider knowledge and proficiency of skills required to perform one's job well</label>
              <p-dropdown (onChange)="onReasonChange($event)" [options]="evaluationOptions" formControlName="knowledgeOfJob"></p-dropdown>
              <input class="form-control mt-2" formControlName="knowledgeOfJob_reason" placeholder="Type your reason" [hidden]="!knowledgeOfJobReason">
            </div>
            <div class="form-group">
              <h3 class="required">Communication & Interpersonal Skills</h3>
              <label>Consider the ability to communicate the message across clearly and get the desired effect</label>
              <p-dropdown (onChange)="onReasonChange($event)" [options]="evaluationOptions" formControlName="communication"></p-dropdown>
              <input class="form-control mt-2" formControlName="communication_reason" placeholder="Type your reason" [hidden]="!communicationReason">
            </div>
            <div class="form-group">
              <h3 class="required">Problem Solving</h3>
              <label>Effectiveness in thinking through problems, creatively evaluating alternatives before reaching sound conclusions in a timely manner</label>
              <p-dropdown (onChange)="onReasonChange($event)" [options]="evaluationOptions" formControlName="problemSolving"></p-dropdown>
              <input class="form-control mt-2" formControlName="problemSolving_reason" placeholder="Type your reason" [hidden]="!problemSolvingReason">
            </div>
            <div class="form-group">
              <h3 class="required">Teamwork and Cooperation</h3>
              <label>Consider attitude towards peers and supervisors. Willingness to contribute to team efforts and share knowledge and expertise with others</label>
              <p-dropdown (onChange)="onReasonChange($event)" [options]="evaluationOptions" formControlName="teamWorkAndCooperation"></p-dropdown>
              <input class="form-control mt-2" formControlName="teamWorkAndCooperation_reason" placeholder="Type your reason" [hidden]="!teamworkandCooperationReason">
            </div>
            <div class="form-group">
              <h3 class="required">Business Acumen (For Leads/Managers Only) </h3>
              <label>Consider the ability to see the big picture</label>
              <p-dropdown (onChange)="onReasonChange($event)" [options]="evaluationOptions" formControlName="businessAcumen"></p-dropdown>
              <input class="form-control mt-2" formControlName="businessAcumen_reason" placeholder="Type your reason" [hidden]="!businessAcumenReason">
            </div>
            <div class="form-group">
              <h3 class="required">Decision Making (For Leads/Managers Only)</h3>
              <label>Consider the ability to understand the problems, evaluate possible solutions by gathering information.</label>
              <p-dropdown (onChange)="onReasonChange($event)" [options]="evaluationOptions" formControlName="decisionMaking"></p-dropdown>
              <input class="form-control mt-2" formControlName="decisionMaking_reason" placeholder="Type your reason" [hidden]="!decisionMakingReason">
            </div>
            <div class="form-group mb-0">
              <button class="btn btn-primary" (click)="savePerfBasedKPISForm(); onFormSubmit()" [disabled]='!performanceBasedKPI.valid || isSubmitted'>Save</button>
            </div>
          </form>
        </div> 
      </p-accordionTab>
      <p-accordionTab>
        <p-header>
          Area Of Improvment And Future Training Needs
          <i [ngClass]="!areaOfImrovmentAndTraining.invalid && 'pi pi-check'"></i>
        </p-header>
        <div class="detail-box mb-3">
          <p>Based on the challenges in the current review period, please identify the key areas to improve and identify the training needs to help the employee grow professionally.</p>
        </div>
        <div class="form-holder">
          <form [formGroup]="areaOfImrovmentAndTraining">
            <div class="form-group">
              <h3 class="required">Areas of Improvement (min 3, max 5)</h3>
            </div>
            <div formArrayName="areaOfImprovments" *ngFor="let _ of areaOfImprovments.controls; index as i">
              <div class="form-group">
                <input class="form-control" [formControlName]="i" placeholder="Area Of Improvment {{i + 1}}">
                <a *ngIf="i > 2 && !isSubmitted" class="delete-form-field" (click)="removeAreaOfImprovments(i)">x</a>
              </div>
            </div>
            <div *ngIf="!isSubmitted" class="form-group add-more d-flex justify-content-end">
              <a (click)="addAreaOfImprovments()" >Add More</a>
            </div>
            <div class="form-group">
              <h3 class="required">Training & Development Needs:</h3>
              <input class="form-control" placeholder="Your Answer" formControlName="trainingNeeds">
            </div>
            <div class="form-group mb-0">
              <button class="btn btn-primary" (click)="saveAreaOfImpAndTrainingForm(); onFormSubmit()" [disabled]='!areaOfImrovmentAndTraining.valid || isSubmitted'>Save</button>
            </div>
          </form>
        </div> 
      </p-accordionTab>
      <p-accordionTab>
        <p-header>
          S.M.A.R.T Goals And Objectives For The Year
          <i [ngClass]="!smartGoalsForm.invalid && 'pi pi-check'"></i>
        </p-header>
        <div class="detail-box mb-3">
          <h4>S.M.A.R.T. goals are:</h4>
          <ul>
            <li><strong>Specific:</strong> Well defined, clear, and unambiguous</li>
            <li><strong>Measurable:</strong> With specific criteria that measure your progress towards the accomplishment of the goal</li>
            <li><strong>Achievable:</strong> Attainable and not impossible to achieve</li>
            <li><strong>Realistic:</strong> Within reach, realistic, and relevant to your life purpose</li>
            <li><strong>Timely:</strong> With a clearly defined timeline, including a starting date and a target date.</li>
          </ul>
        </div>
        <div class="form-holder">
          <form [formGroup]="smartGoalsForm">
            <div class="form-group">
              <h3 class="required">Provide S.M.A.R.T. Goals for the next evaluation period (min 3, max 5)</h3>
            </div>
            <div formArrayName="smartGoals" *ngFor="let _ of smartGoals.controls; index as i">
              <div class="form-group">
                <input class="form-control" [formControlName]="i" placeholder="Goal {{i + 1}}"/>
                <a *ngIf="i > 2 && !isSubmitted" class="delete-form-field" (click)="removeSmartGoals(i)">x</a>
              </div>
            </div>
            <div *ngIf="!isSubmitted" class="form-group add-more d-flex justify-content-end">
              <a (click)="addSmartGoals()">Add More</a>
            </div>
            <div class="form-group mb-0">
              <button class="btn btn-primary" (click)="saveSMARTGoalsForm(); onFormSubmit()" [disabled]='!smartGoalsForm.valid || isSubmitted'>Save</button>
            </div>
          </form>
        </div> 
      </p-accordionTab>
    </p-accordion>
  </div>
  <div [hidden]="!isSubmitted" class="mt-2 alert alert-success" role="alert">
    <strong>{{message}}</strong>
  </div>
  <div class="form-group mt-3 float-right">
    <button class="btn btn-primary" (click)="submitEvaluationForm()" [disabled]="checkDisableSubmit()"><span class="loader" *ngIf="showLoader"></span> Submit</button>
  </div>
</div>

<p-dialog header="Definitions: " [(visible)]="display" styleClass="mydialog">
  <div class="value-base-kpi">
    <p><strong>Exceptional:</strong> Performance far exceeded expectations due to exceptionally high quality of work performed in all essential areas of responsibility, resulting in an overall quality of work that was superior; and either 1) included the completion of multiple major goals or projects, or 2) made an exceptional or unique contribution in support of unit, department, or company's objectives. This rating is achievable by any employee though given infrequently.</p>
    <p><strong>Exceeds expectations:</strong> Performance consistently exceeded expectations in all essential areas of responsibility, and the quality of work overall was excellent. Annual goals were met.</p>
    <p><strong>Meets expectations:</strong> Performance consistently met expectations in all essential areas of responsibility, at times possibly exceeding expectations, and the quality of work overall was very good. The most critical annual goals were met.</p>
    <p><strong>Improvement needed:</strong> Performance did not consistently meet expectations – performance failed to meet expectations in one or more essential areas of responsibility, and/or one or more of the most critical goals were not met. A professional development plan to improve performance must be developed, including timelines, and monitored to measure progress.</p>
    <p><strong>Unsatisfactory:</strong> Performance was consistently below expectations in most essential areas of responsibility, and/or reasonable progress toward critical goals was not made. Significant improvement is needed in one or more important areas. A plan to correct performance, including timelines, must be outlined and monitored to measure progress.</p>
  </div>
</p-dialog>
<p-toast position="top-left"></p-toast>
<div class="loader-overlay" *ngIf="showLoader">
  <span class="loader"></span>
</div>