<div class="register-box">
  <div class="form-holder">
    <h2>Edit User</h2>
    <form>
      <div class="form-group">
        <input type="number" class="form-control" placeholder="Employee ID" value="3">
      </div>
      <div class="form-group">
        <input type="text" class="form-control" placeholder="Full Name" value="john">
      </div>
      <div class="form-group">
        <input type="email" class="form-control" placeholder="Email" value="john@gmail.com">
      </div>
      <div class="form-group">
        <p-dropdown [options]="designation" [style]="{'width':'100%'}" [filter]="true"></p-dropdown>
      </div>
      <div class="form-group">
        <p-dropdown [options]="supervisor" [style]="{'width':'100%'}" [filter]="true"></p-dropdown>
      </div>
      <div class="form-group">
        <p-dropdown [options]="department" [style]="{'width':'100%'}" [filter]="true"></p-dropdown>
      </div>
      <div class="form-group">
        <p-dropdown [options]="workLocation" [style]="{'width':'100%'}" [filter]="true"></p-dropdown>
      </div>
      <div class="form-group">
        <button class="btn btn-primary">Update</button>
      </div>
    </form>
  </div>
</div>
