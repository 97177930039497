import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './admin.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RegisterComponent } from '../register/register.component';
import { AllEmployeeListComponent } from './all-employee-list/all-employee-list.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { EvaluationFormComponent } from './evaluation-form/evaluation-form.component';
import { UnderSupervisionComponent } from './under-supervision/under-supervision.component';


const routes: Routes = [
    {
        path: '',
        component: AdminComponent,
        children: [
            { path: "dashboard", component: DashboardComponent },
            { path: "register", component: RegisterComponent },
            { path: "admin/edituser", component: EditUserComponent },
            { path: "all-employee-list", component: AllEmployeeListComponent },
            { path: "evaluation-form/:EmpID/:year", component: EvaluationFormComponent },
            { path: "evaluation-form/:EmpID", component: EvaluationFormComponent },
            { path: "evaluation-form", component: EvaluationFormComponent },
            { path: "under-supervision", component: UnderSupervisionComponent },
            { path: '', component: EvaluationFormComponent },
        ]
    }
];

export const AdminRoutingModule = RouterModule.forChild(routes);