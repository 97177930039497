import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_URL } from './../../utilities/constants';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  resourceUrl = `${API_URL}/auth/google-login`;

  constructor(protected http: HttpClient) { }

  googleLogin(token: any): Observable<any> {
    return this.http.post<any>(this.resourceUrl, token, { observe: 'response' });
  }

}
