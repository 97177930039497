import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_URL } from './../../utilities/constants'

@Injectable({
  providedIn: 'root'
})
export class FrontendService {
  SERVER_API_URL = API_URL;

  constructor(
    protected http: HttpClient
    ) { }


  getEmployees(year): Observable<any> {
    return this.http
      .get(this.SERVER_API_URL + '/admin/all-employees/' + year, { observe: 'response' })
      .pipe((res) => res);
  }

  getDirectSupervisors(): Observable<any> {
    return this.http
      .get(this.SERVER_API_URL + '/shared/all-direct-supervisor', { observe: 'response' })
      .pipe((res) => res);
  }

  getDesignation(): Observable<any> {
    return this.http
      .get(this.SERVER_API_URL + '/shared/all-designation', { observe: 'response' })
      .pipe((res) => res);
  }

  getLocationsClients(): Observable<any> {
    return this.http
      .get(this.SERVER_API_URL + '/shared/all-locations-clients', { observe: 'response' })
      .pipe((res) => res);
  }
  
  getDepartments(): Observable<any> {
    return this.http
      .get(this.SERVER_API_URL + '/shared/all-department', { observe: 'response' })
      .pipe((res) => res);
  }

  getAllReportees(data): Observable<any> {
    return this.http
      .get(this.SERVER_API_URL + '/user/all-reportees', { params: data, observe: 'response' })
  }

  getEvaluationForm(data): Observable<any> {
    return this.http
      .get(this.SERVER_API_URL + '/user/get-evaluation-form', { params: data, observe: 'response' })
  }

  getEvaluationOptions(): Observable<any> {
    return this.http
      .get(this.SERVER_API_URL + '/shared/all-evaluation-options', { observe: 'response' })
  }

  saveFormEvaluationForm(data): Observable<any> {
    return this.http
      .post(this.SERVER_API_URL + '/user/save-evaluation-form', data, { observe: 'response' })
  }
  
  submitEvaluationForm(data): Observable<any> {
    return this.http
      .post(this.SERVER_API_URL + '/user/save-evaluation-form', data, { observe: 'response' })
  }
  // TODO: ID should be dynamic
  editForm(data): Observable<any> {
    return this.http
      .get(this.SERVER_API_URL + '/admin/edit-evaluation-form?year=' + data.Year + '&emp_id=' + data.SelfSubmittedID +'&form_id=' + data.SelfFormID, { observe: 'response' })
  }
  editFormManager(data): Observable<any> {
    return this.http
      .get(this.SERVER_API_URL + '/admin/edit-evaluation-form?year=' + data.Year + '&emp_id=' + data.ManagerSubmittedID + '&form_id=' + data.ManagerFormID, { observe: 'response' })
  }
}
