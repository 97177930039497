<div class="card-box">
  <div class="head">
    <strong class="logo">
      <a [routerLink]="['/login']" routerLinkActive="router-link-active">
        <img src="../../../mpes/assets/images/logo-white.svg" alt="Mobilelive">
      </a>
    </strong>
    <h2>Performance Evaluation System</h2>
  </div>
  <div class="box">
    <div class="left-align"></div>
    <div class="right-align">
      <div class="form-holder">
        <h2>Sign In</h2>
        <!-- Enable button once comments thing is completed -->
        <button class="google-btn" (click)="signInWithGoogle()" disabled="true" style="background-color: #cccccc;"><span
          class="pi pi-google"></span>Sign in with Google</button>
          <p class="alert alert-info mt-3">MobileLIVE Performance Evaluation System will be activated on Saturday, December 12th.</p>
          <p *ngIf="errorMessage?.error" class="alert alert-danger mt-3">{{ errorMessage?.error }}</p>
      </div>
    </div>
  </div>
</div>