import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { isAuth, getCookie } from '../../utilities/helpers';
import decode from 'jwt-decode';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  user: any;
  menuClass = 'closed';

  constructor(public router: Router) { }

  ngOnInit() {
    // Token expiration validation
    const token = getCookie('token');
    if (token) {
      const tokenDetails = decode(token);
      const dateUnix = new Date().getTime() / 1000;
      const isTokenInvalid = dateUnix > tokenDetails.exp;
      if (isTokenInvalid) {
        this.router.navigate(['/login']);
      }
    } else {
      this.router.navigate(['/login']);
    }
    this.user = isAuth();
    // Redirect to dashboard even if token not exist
    // this.router.navigate(['/admin/dashboard']);
  }
  toggleSidebar() {
    if(this.menuClass == "closed"){
      this.menuClass = 'opened';
    } else {
      this.menuClass = 'closed';
    }
  }
}
