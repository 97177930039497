import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { AdminModule } from './components/admin/admin.module';
import { AuthGuard } from './auth/auth.guard';


const routes: Routes = [
  {
    path: 'admin',
    loadChildren: './components/admin/admin.module#AdminModule',
    canActivate: [AuthGuard]
  },
  { path: 'login', component: LoginComponent },
  { path: 'forget-password', component: ForgetPasswordComponent },
  { path: '**', redirectTo: 'admin'}
];


@NgModule({
  imports: [AdminModule, RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
