<div class="register-box">
  <div class="form-holder">
    <h2>Register New User</h2>
    <form>
      <div class="form-group">
        <input type="number" class="form-control" placeholder="Employee ID">
      </div>
      <div class="form-group">
        <input type="text" class="form-control" placeholder="Full Name">
      </div>
      <div class="form-group">
        <input type="email" class="form-control" placeholder="Email">
      </div>
      <div class="form-group">
        <p-dropdown [options]="designation" [style]="{'width':'100%'}" [filter]="true"></p-dropdown>
      </div>
      <div class="form-group">
        <p-dropdown [options]="supervisor" [style]="{'width':'100%'}" [filter]="true"></p-dropdown>
        <!-- TODO: As i am working on it -->
        <!-- <p-multiSelect [options]="cities1" [(ngModel)]="selectedCities1" name="supervisor"></p-multiSelect> -->
      </div>
      <div class="form-group">
        <p-dropdown [options]="department" [style]="{'width':'100%'}" [filter]="true"></p-dropdown>
      </div>
      <div class="form-group">
        <p-dropdown [options]="workLocation" [style]="{'width':'100%'}" [filter]="true"></p-dropdown>
      </div>
      <div class="form-group">
        <input type="password" class="form-control" placeholder="Password">
      </div>
      <div class="form-group">
        <button class="btn btn-primary">Submit</button>
      </div>
    </form>
  </div>
</div>
