<div class="chart-outer">
    <div class="row">
        <div class="col-sm-12 col-md-6">
            <div class="chart-holder">
                <app-pi-chart></app-pi-chart>
            </div>
        </div>
        <div class="col-sm-12 col-md-6">
            <div class="chart-holder">
                <app-bar-chart></app-bar-chart>
            </div>
        </div>
        <div class="col-sm-12 col-md-6">
            <div class="chart-holder">
                <app-line-chart></app-line-chart>
            </div>
        </div>
        <div class="col-sm-12 col-md-6">
            <div class="chart-holder">
                <app-polar-area-chart></app-polar-area-chart>
            </div>
        </div>
    </div>
</div>