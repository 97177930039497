<app-header></app-header>
<div class="side-menu" [ngClass]="menuClass">
  <input type="checkbox" (click)="toggleSidebar()" id="toggler" class="toggler">
  <div class="hamburger">
    <div></div>
  </div>
  <label class="overlay" for="toggler"></label>
  <div class="sidebar menu" [ngClass]="menuClass">
    <div class="profile-details">
      <strong class="name"><a>{{ user.email }}</a></strong>
      <span class="employee-id">Employee Id: <span>{{ user.emp_no }}</span></span>
    </div>
    <ul>
      <li *ngIf="user.role === 'Admin'"><a (click)="toggleSidebar()" [routerLink]="['/admin/dashboard']" routerLinkActive="is-active">Dashboard</a></li>
      <li *ngIf="user.role === 'Admin'"><a (click)="toggleSidebar()" [routerLink]="['/admin/all-employee-list']" routerLinkActive="is-active">All Empolyees</a></li>
      <li><a (click)="toggleSidebar()" [routerLink]="['/admin/evaluation-form']" routerLinkActive="is-active">Evaluation Form</a></li>
      <li *ngIf="user.isManager === 1"><a (click)="toggleSidebar()" [routerLink]="['/admin/under-supervision']" routerLinkActive="is-active">Assigned Subordinates</a></li>
    </ul>
  </div>
</div>
<div class="content-panel">
  <router-outlet></router-outlet>
</div>
<app-footer></app-footer>