import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SelectItem } from 'primeng/api/selectitem';
import { User } from '../../../shared/interfaces/user';
import { FrontendService } from 'src/app/shared/services/frontend.service';
import { Router, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ConfirmationService, MessageService } from 'primeng/api';
import * as _ from 'lodash';

@Component({
  selector: 'app-all-employee-list',
  templateUrl: './all-employee-list.component.html',
  styleUrls: ['./all-employee-list.component.css'],
  providers: [
    ConfirmationService,
    MessageService
  ],
})
export class AllEmployeeListComponent implements OnInit {
  users: User[];
  cols: any[];
  department: SelectItem[];
  designation: SelectItem[];
  supervisor: SelectItem[];
  workLocation: SelectItem[];
  status: SelectItem[];
  ManagerStatus: SelectItem[];
  year: SelectItem[];
  selectedYear = new Date().getFullYear();
  display = false;
  msgs: any;
  empId: any;
  showLoader = false;
  first = 0;
  loading = false;

  @ViewChild('dt', { static: false }) dt?: ElementRef;
  constructor(
    private frontEndService: FrontendService,
    private router: Router,
    private confirmationService: ConfirmationService,
    protected activatedRoute: ActivatedRoute,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    const queryParams = _.cloneDeep(this.activatedRoute.snapshot.queryParams);
    if (queryParams && queryParams.selectedYear) {
      this.selectedYear = queryParams.selectedYear;
    }
    this.getEmployees(this.selectedYear);
    this.status = [
      { label: 'All', value: null },
      { label: 'Pending', value: 'Pending' },
      { label: 'Submitted', value: 'Submitted' },
    ];
    this.ManagerStatus = [
      { label: 'All', value: null },
      { label: 'Pending', value: 'Pending' },
      { label: 'Submitted', value: 'Submitted' },
    ];

    this.year = [];
    const date = new Date();
    const year = date.getFullYear();
    for (let i = year; i >= 2020; i--) {
      this.year.push({ label: i.toString(), value: i.toString() });
    }

    this.getDirectSupervisors();
    this.getDesignation();
    this.getLocationsClients();
    this.getDepartments();
  }

  confirm(user) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to make the evaluation form editable for the employee?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.editForm(user);
      },
      reject: () => {
        this.msgs = [{ severity: 'info', summary: 'Rejected', detail: 'You have rejected' }];
      }
    });
    this.display = true;
  }
  confirmManager(user) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to make the evaluation form editable for the manager??',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.editFormManager(user);
      },
      reject: () => {
        this.msgs = [{ severity: 'info', summary: 'Rejected', detail: 'You have rejected' }];
      }
    });
    this.display = true;
  }
  reject() {
    this.display = false;
  }

  yearChange() {
    // this.router.navigate(['/admin/all-employee-lists']);
    const queryParams = [];
    queryParams['selectedYear'] = this.selectedYear;
    this.router.navigate(['/admin/all-employee-list'], { queryParams });
    this.getEmployees(this.selectedYear);

  }

  getEmployees(year) {
    this.loading = true;
    this.frontEndService.getEmployees(year).subscribe((res) => {
      // this.users = res.body.employees;
      this.users = res.body.employees.map(user => {
        user.SelfStatus = (user.SelfStatus == 'Submitted' ? 'Submitted' : 'Pending');
        user.ManagerStatus = (user.ManagerStatus == 'Submitted' ? 'Submitted' : 'Pending');
        return user;
      });
      this.first = 0;
      this.loading = false;
    });
  }

  getDirectSupervisors() {
    this.frontEndService.getDirectSupervisors().subscribe((res) => {
      const supervisors = res.body.direct_supervisors;
      this.supervisor = supervisors.map(sup => {
        return {
          label: sup.label,
          value: sup.label
        }
      })
      this.supervisor.unshift({ label: 'All Supervisors', value: null });
    });
  }

  getDesignation() {
    this.frontEndService.getDesignation().subscribe((res) => {
      const designations = res.body.designations;
      this.designation = designations.map(des => {
        return {
          label: des.label,
          value: des.label
        }
      })
      this.designation.unshift({ label: 'All Designations', value: null });
    });
  }

  getLocationsClients() {
    this.frontEndService.getLocationsClients().subscribe((res) => {
      const workLocations = res.body.locations_clients;
      this.workLocation = workLocations.map(loc => {
        return {
          label: loc.label,
          value: loc.label
        }
      })
      this.workLocation.unshift({ label: 'All Locations', value: null });
    });
  }

  getDepartments() {
    this.frontEndService.getDepartments().subscribe((res) => {
      const departments = res.body.departments;
      this.department = departments.map(dep => {
        return {
          label: dep.label,
          value: dep.label
        }
      })
      this.department.unshift({ label: 'All Departments', value: null });
    });
  }


  reviewForm(user, reviewSelfSubmitted = false, editable = false) {
    this.router.navigate(['/admin/evaluation-form/' + user.ID + '/' + user.Year],
      { state: { name: user.EmpName, showSelf: reviewSelfSubmitted, editable } });
  }

  editForm(user) {
    this.showLoader = true;
    this.frontEndService.editForm(user).subscribe((res) => {
      setTimeout(() => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Form is editable for the employee' });
        this.getEmployees(this.selectedYear);
        this.showLoader = false;
      }, 500)
    })
  }
  editFormManager(user) {
    this.showLoader = true;
    this.frontEndService.editFormManager(user).subscribe((res) => {
      setTimeout(() => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Form is editable for the Manager' });
        this.getEmployees(this.selectedYear);
        this.showLoader = false;
      }, 500)
    })
  }
}
