import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";
import { SocialUser } from "angularx-social-login";
import { LoginService } from './login.service';
import { authenticate, isAuth, signout } from './../../utilities/helpers';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  user: SocialUser;
  loggedIn: boolean;
  sub: Subscription;
  errorMessage: any;

  constructor(
    private router: Router,
    private authService: AuthService,
    private loginService: LoginService,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.sub = this.activatedRoute
      .queryParams
      .subscribe(params => {
        const { action } = params;
        console.log(action);
        if (action === 'auto') {
          this.authService.signIn(GoogleLoginProvider.PROVIDER_ID)
            .then(res => {
              this.authenticateUser(res);
            })
            .catch(error => {
              console.log('Error logging to Google', error);
            });
        }
      });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID)
      .then(res => {
        this.authenticateUser(res);
      })
      .catch(error => {
        console.log('Error logging to Google', error);
      });
  }

  private authenticateUser(user) {
    this.loginService.googleLogin({ idToken: user.idToken }).subscribe((response) => {
      console.log('GOOGLE SIGNIN SUCCESS', response);
      authenticate(response, () => {
        if (isAuth()) {
          const user = isAuth();
          if(user.role === 'Admin'){
            this.router.navigate(['./admin/dashboard']);
          } else {
            this.router.navigate(['evaluation-form']);
          }
        }
      });
    }, error => {
      console.log('GOOGLE SIGNIN SUCCESS', error);
      if (error.status === 400) {
        this.errorMessage = error.error;
        localStorage.clear();
        signout(() => {
          this.authService.signOut();
          this.router.navigate(['/login']);
        });
      }
    });
    console.log(user);
    this.user = user;
    this.loggedIn = (user != null);
  }

}
