import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api/selectitem';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {

  department: SelectItem[];
  designation: SelectItem[];
  supervisor: SelectItem[];
  workLocation: SelectItem[];

  constructor() { }

  ngOnInit() {
    this.designation = [
      { label: 'Designation', value: null },
      { label: 'Software Engineer', value: 'Software Engineer' },
      { label: 'Sr Software Engineer', value: 'Sr Software Engineer' },
      { label: 'Tech Lead', value: 'Tech Lead' },
      { label: 'Software Architect', value: 'Software Architect' }
    ];
    this.supervisor = [
      { label: 'Direct Supervisor(s)', value: null },
      { label: 'Kashif', value: 'Kashif' },
      { label: 'Waqas', value: 'Waqas' },
      { label: 'Usman', value: 'Usman' },
      { label: 'Faheem', value: 'Faheem' }
    ];
    this.department = [
      { label: 'Departments', value: null },
      { label: 'Development', value: 'Development' },
      { label: 'Design', value: 'Design' },
      { label: 'BA/BSA', value: 'BA/BSA' },
      { label: 'Network', value: 'Network' },
      { label: 'HR', value: 'HR' }
    ];
    this.workLocation = [
      { label: 'Work Location And Client', value: null },
      { label: 'Bell (Mississauga)', value: 'Bell (Mississauga)' },
      { label: 'Bell (Montreal)', value: 'Bell (Montreal)' },
      { label: 'CIBC (Downtown)', value: 'CIBC (Downtown)' },
      { label: 'Freedom (Downtown)', value: 'Freedom (Downtown)' },
      { label: 'HQ (Downtown)', value: 'HQ (Downtown)' },
      { label: 'Lab (Richmond Hill)', value: 'Lab (Richmond Hill)' },
      { label: 'Offshore Office (Lahore)', value: 'Offshore Office (Lahore)' },
      { label: 'Paradigm Quest (Downtown)', value: 'Paradigm Quest (Downtown)' },
      { label: 'Rogers (Brampton)', value: 'Rogers (Brampton)' },
      { label: 'Rogers (Montreal)', value: 'Rogers (Montreal)' },
      { label: 'Rogers (OMP)', value: 'Rogers (OMP)' },
      { label: 'TELUS (Downtown)', value: 'TELUS (Downtown)' },
      { label: 'TELUS (Scarborough)', value: 'TELUS (Scarborough)' }
    ];
  }

}
