<div class="title-holder">
  <h3 class="page-title">Assigned Subordinates</h3>
  <div class="year-dropdown">
    <p-dropdown [options]="year" (onChange)="yearChange()" [(ngModel)]="selectedYear"></p-dropdown>
  </div>
</div>
  <div class="employee-table">
  <p-table #dt [value]="users" [paginator]="true" [rows]="10">
    <ng-template pTemplate="header">
        <tr>
            <th>Employee ID</th>
            <th>Employee Name</th>
            <th>Email Address</th>
            <th>Designation</th>
            <th>Department</th>
            <th>Work Location And Client</th>
            <th>Self Evaluation Status</th>
            <th>Manager Review</th>
        </tr>
        <tr>
          <th>
            <input pInputText type="text" (input)="dt.filter($event.target.value, 'EmpID', 'contains')" placeholder="Enter ID">
          </th>
          <th>
            <input pInputText type="text" (input)="dt.filter($event.target.value, 'EmpName', 'contains')" placeholder="Enter Name">
          </th>
          <th>
            <input pInputText type="text" (input)="dt.filter($event.target.value, 'EmpEmailAddress', 'contains')" placeholder="Enter Email">
          </th>
          <th>
            <p-dropdown [options]="designation" (onChange)="dt.filter($event.value, 'Designation', 'equals')"></p-dropdown>
          </th>
          <th>
            <p-dropdown [options]="department" (onChange)="dt.filter($event.value, 'EmpDepartments', 'equals')"></p-dropdown>
          </th>
          <th>
            <p-dropdown [options]="workLocation" (onChange)="dt.filter($event.value, 'WorkLocationAndClient', 'equals')"></p-dropdown>
          </th>
          <th>
            <p-dropdown [options]="status" (onChange)="dt.filter($event.value, 'SelfStatus', 'equals')"></p-dropdown>
          </th>
          <th>
            <p-dropdown [options]="reviews" (onChange)="dt.filter($event.value, 'ManagerStatus', 'equals')"></p-dropdown>
          </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-user>
        <tr>
          <td>{{user.EmpID}}</td>
          <td>{{user.EmpName}}</td>
          <td class="w-256">{{user.EmpEmailAddress}}</td>
          <td>{{user.Designation}}</td>
          <td>{{user.EmpDepartments}}</td>
          <td>{{user.WorkLocationAndClient}}</td>
          <td>{{ user.SelfStatus }} <button class="cta-btn" *ngIf="user.SelfStatus == 'Submitted'" (click)="reviewForm(user, true)" pTooltip="view form" tooltipPosition="top"><i class="fa fa-info-circle"></i></button></td>
          <td class="with-btn">
            <button class="btn btn-primary btn-small" *ngIf="user.ManagerStatus !='Submitted'; else prevYear" (click)="todo(user)" [disabled]="(user.SelfStatus == 'Pending') || (user.SelfStatus == '')">Todo</button>
            <ng-template #prevYear>
              <button class="btn btn-primary btn-small green" (click)="reviewForm(user)">Review</button>
            </ng-template>
          </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
          <td colspan="100%">
              No records found
          </td>
      </tr>
    </ng-template>
  </p-table>
</div>