<div class="title-holder">
  <h3 class="page-title mb-3">All Employee List</h3>
  <div class="year-dropdown">
    <p-dropdown [options]="year" (onChange)="yearChange()" [(ngModel)]="selectedYear"></p-dropdown>
  </div>
</div>
  <div class="employee-table">
  <p-table #dt [value]="users" [paginator]="true" [rows]="10" [(first)]="first" [loading]="loading">
    <ng-template pTemplate="header">
        <tr>
            <th>Employee ID</th>
            <th>Employee Name</th>
            <th class="w-256">Email Address</th>
            <th>Designation</th>
            <th>Direct Supervisor(s)</th>
            <th>Department</th>
            <th>Work Location And Client</th>
            <th>Self Evaluation Status</th>
            <th>Manager Evaluation Status</th>
        </tr>
        <tr>
          <th>
            <input pInputText type="text" (input)="dt.filter($event.target.value, 'EmpID', 'contains')" placeholder="Enter ID">
          </th>
          <th>
            <input pInputText type="text" (input)="dt.filter($event.target.value, 'EmpName', 'contains')" placeholder="Enter Name">
          </th>
          <th>
            <input pInputText type="text" (input)="dt.filter($event.target.value, 'EmpEmailAddress', 'contains')" placeholder="Enter Email">
          </th>
          <th>
            <p-dropdown [options]="designation" (onChange)="dt.filter($event.value, 'Designation', 'equals')"></p-dropdown>
          </th>
          <th>
            <p-dropdown [options]="supervisor" (onChange)="dt.filter($event.value, 'EmpDirectSupervisors', 'equals')"></p-dropdown>
          </th>
          <th>
            <p-dropdown [options]="department" (onChange)="dt.filter($event.value, 'EmpDepartments', 'equals')"></p-dropdown>
          </th>
          <th>
            <p-dropdown [options]="workLocation" (onChange)="dt.filter($event.value, 'WorkLocationAndClient', 'equals')"></p-dropdown>
          </th>
          <th>
            <p-dropdown [options]="status" (onChange)="dt.filter($event.value, 'SelfStatus', 'equals')"></p-dropdown>
          </th>
          <th>
            <p-dropdown [options]="ManagerStatus" (onChange)="dt.filter($event.value, 'ManagerStatus', 'equals')"></p-dropdown>
          </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-user>
        <tr>
          <td>{{user.EmpID}}</td>
          <td>{{user.EmpName}}</td>
          <td>{{user.EmpEmailAddress}}</td>
          <td>{{user.Designation}}</td>
          <td>{{user.EmpDirectSupervisors}}</td>
          <td>{{user.EmpDepartments}}</td>
          <td>{{user.WorkLocationAndClient}}</td>
          <td>
            {{user.SelfStatus }} 
            <ul class="todo-button">
              <li><button (click)="reviewForm(user, true)" class="cta-btn" *ngIf="user.SelfStatus == 'Submitted'" pTooltip="view form" tooltipPosition="top"><i class="fa fa-info-circle"></i></button></li>
              <li><button class="cta-btn" (click)="confirm(user)" *ngIf="user.SelfStatus == 'Submitted'" pTooltip="Make this form editable for {{user.EmpName}}" tooltipPosition="top"><i class="fa fa-edit"></i></button></li>
            </ul>
          </td>
          <td>
            {{user.ManagerStatus }}
            <ul class="todo-button">
              <li><button class="cta-btn" (click)="reviewForm(user)" *ngIf="user.ManagerStatus == 'Submitted'" pTooltip="view form" tooltipPosition="top"><i class="fa fa-info-circle"></i></button></li>
              <li><button class="cta-btn" (click)="confirmManager(user)" *ngIf="user.ManagerStatus == 'Submitted'" pTooltip="Make this form editable for {{user.EmpDirectSupervisors}}" tooltipPosition="top"><i class="fa fa-edit"></i></button></li>
            </ul>
          </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
          <td colspan="100%">
              No records found
          </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<p-confirmDialog [(visible)]="display" header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-toast position="top-left"></p-toast>
<div class="loader-overlay" *ngIf="showLoader">
  <span class="loader"></span>
</div>