import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from "angularx-social-login";
import { signout } from 'src/app/utilities/helpers';
import { isAuth } from '../../../utilities/helpers';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  user: any;
  logout = signout;
  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit() {
    this.user = isAuth();
  }

  signOut(): void {
    signout(() => {
      this.authService.signOut();
      this.router.navigate(['/login']);
    });
  }
}
