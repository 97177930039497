import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api/selectitem';
import { User } from '../../../shared/interfaces/user';
import { FrontendService } from 'src/app/shared/services/frontend.service';
import { isAuth } from '../../../utilities/helpers';
import { Router } from '@angular/router';

@Component({
  selector: 'app-under-supervision',
  templateUrl: './under-supervision.component.html',
  styleUrls: ['./under-supervision.component.css']
})
export class UnderSupervisionComponent implements OnInit {
  users: User[];
  cols: any[];
  department: SelectItem[];
  designation: SelectItem[];
  supervisor: SelectItem[];
  workLocation: SelectItem[];
  status: SelectItem[];
  ManagerStatus: SelectItem[];
  year: SelectItem[];
  review: boolean;
  reviews;
  selectedYear = new Date().getFullYear();
  isCurrentYear = true;

  constructor(
    private frontEndService: FrontendService,
    protected router: Router
  ) { }

  ngOnInit() {    
    this.filDropdowns();
    this.getAllReportees();
    this.getDesignation();
    this.getLocationsClients();
    this.getDepartments();
  }

  filDropdowns(){
    this.status = [
      { label: 'All', value: null },
      { label: 'Pending', value: 'Pending' },
      { label: 'Submitted', value: 'Submitted' },
    ];

    this.reviews = [
      { label: 'All', value: null },
      { label: 'Review', value: 'Submitted' },
      { label: 'Todo', value: 'Pending' },
    ];
    this.ManagerStatus = [
      { label: 'All', value: null },
      { label: 'Review', value: 'Submitted' },
      { label: 'Todo', value: 'Pending' },
    ];

    this.year = [];

    const date = new Date();
    const year = date.getFullYear();
    for (let i = year; i >= 2020; i--) {
      this.year.push({ label: i.toString(), value: i.toString() });
    }
  }

  yearChange() {
    this.isCurrentYear = this.selectedYear ==  new Date().getFullYear() ? true : false;
    this.getAllReportees();
  }

  getAllReportees() {
    this.frontEndService.getAllReportees({year: this.selectedYear, emp_id: isAuth().emp_id}).subscribe((res) => {
      this.users = res.body.reportees.map(user => {
        user.SelfStatus = (user.SelfStatus == 'Submitted' ? 'Submitted': 'Pending');
        user.ManagerStatus = (user.ManagerStatus == 'Submitted' ? 'Submitted': 'Pending');
        return user;
      })
    });
  }

  getDesignation() {
    this.frontEndService.getDesignation().subscribe((res) => {
      const designations = res.body.designations;
      this.designation = designations.map( des => {
        return {
          label: des.label, 
          value: des.label
        }
      })
      this.designation.unshift({label: 'All Designations', value: null});
    });
  }

  getLocationsClients() {
    this.frontEndService.getLocationsClients().subscribe((res) => {
      const workLocations = res.body.locations_clients;
      this.workLocation = workLocations.map( loc => {
        return {
          label: loc.label, 
          value: loc.label
        }
      })
      this.workLocation.unshift({label: 'All Locations', value: null});
    });
  }
  
  getDepartments() {
    this.frontEndService.getDepartments().subscribe((res) => {
      const departments = res.body.departments;
      this.department = departments.map( dep => {
        return {
          label: dep.label, 
          value: dep.label
        }
      })
      this.department.unshift({label: 'All Departments', value: null});
    });
  }

  todo(user) {
    this.router.navigate(['/admin/evaluation-form/' + user.ID], { state: { name:user.EmpName, showSelf: false } });
  }

  reviewForm(user, reviewSelfSubmitted = false) {    
    this.router.navigate(['/admin/evaluation-form/' + user.ID], { state: { name : user.EmpName, showSelf: reviewSelfSubmitted } });
  }
}