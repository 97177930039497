import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api/selectitem';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { FrontendService } from 'src/app/shared/services/frontend.service';
import { isAuth } from '../../../utilities/helpers';
import * as _ from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ArrayType } from '@angular/compiler';

@Component({
  selector: 'app-evaluation-form',
  templateUrl: './evaluation-form.component.html',
  styleUrls: ['./evaluation-form.component.css'],
  providers: [MessageService]
})
export class EvaluationFormComponent implements OnInit {
  year: SelectItem[];
  departments: SelectItem[];
  empathetic: SelectItem[];
  innovative: SelectItem[];
  persevering: SelectItem[];
  reliable: SelectItem[];
  inclusive: SelectItem[];
  qualityOfWork: SelectItem[];
  knowledgeOfJob: SelectItem[];
  communication: SelectItem[];
  problemSolving: SelectItem[];
  teamworkandCooperation: SelectItem[];
  businessAcumen: SelectItem[];
  decisionMaking: SelectItem[];
  formYear: string = '2020';
  filled_status: boolean = false;
  display: boolean = false;
  selectedDepartment: string;
  empName: string;
  basicDetailForm: FormGroup;
  submitted = false;
  selectedYear = new Date().getFullYear();
  formData: any;
  evaluationOptions: SelectItem[];
  valueBasedKPI: FormGroup;
  performanceBasedKPI: FormGroup;
  areaOfImrovmentAndTraining: FormGroup;
  smartGoalsForm: FormGroup;
  currentYear = new Date().getFullYear();
  isCurrentYear = false;
  sectionOneData: any;
  formSections = [false, false, false, false, false];
  isSubmitted = false;
  empId: any;
  message = 'Form has already been submitted!';
  singleFormSubmitted = false;
  showLoader = false;
  myForm: any = [];
  goals: FormArray;
  areaOfImprovement: FormArray;
  cross = true;
  acompsArray = [];
  improvemntsArray = [];
  goalsArray = [];
  isEditable = false;
  index = -1;
  selected = false;
  empatheticReason = false;
  innovativeReason = false;
  perseveringReason = false;
  reliableReason = false;
  inclusiveReason = false;
  qualityOfWorkReason = false;
  knowledgeOfJobReason = false;
  communicationReason = false;
  problemSolvingReason = false;
  teamworkandCooperationReason = false;
  businessAcumenReason = false;
  decisionMakingReason = false;

  constructor(
    private location: Location,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private frontEndService: FrontendService,
    private messageService: MessageService) {
  }

  ngOnInit() {
    if (this.isSubmitted == true) {
      this.filled_status = true;
    }
    this.getDepartments();
    const { editable } = window.history.state || {};
    this.isEditable = editable || false;
    this.initForm();
    this.getEvaluationOptions();
  }

  initForm() {
    this.basicDetailForm = this.formBuilder.group({
      employeeName: [{ value: '', disabled: true }, Validators.required],
      position: [{ value: '', disabled: true }, Validators.required],
      directSupervisor: [{ value: '', disabled: true }, [Validators.required, Validators.email]],
      EmpDepartment: [{ value: '', disabled: true }, Validators.required],
      workLocation: [{ value: '', disabled: true }, Validators.required],
      keyAcomps: new FormArray([
      ])
    });
    this.valueBasedKPI = this.formBuilder.group({
      empathetic: [{ value: '' }, Validators.required],
      empathetic_reason: [''],
      innovative: [{ value: '' }, Validators.required],
      innovative_reason: [''],
      persevering: [{ value: '' }, Validators.required],
      persevering_reason: [''],
      reliable: [{ value: '' }, Validators.required],
      reliable_reason: [''],
      inclusive: [{ value: '' }, Validators.required],
      inclusive_reason: [''],
    });
    this.performanceBasedKPI = this.formBuilder.group({
      qualityOfWork: [{ value: '' }, Validators.required],
      qualityOfWork_reason: [''],
      knowledgeOfJob: [{ value: '' }, Validators.required],
      knowledgeOfJob_reason: [''],
      communication: [{ value: '' }, Validators.required],
      communication_reason: [''],
      problemSolving: [{ value: '' }, Validators.required],
      problemSolving_reason: [''],
      teamWorkAndCooperation: [{ value: '' }, Validators.required],
      teamWorkAndCooperation_reason: [''],
      businessAcumen: [{ value: '' }, Validators.required],
      businessAcumen_reason: [''],
      decisionMaking: [{ value: '' }, Validators.required],
      decisionMaking_reason: [''],
    });
    this.areaOfImrovmentAndTraining = this.formBuilder.group({
      trainingNeeds: [{ value: '', enable: true }, Validators.required],
      areaOfImprovments: new FormArray([
      ])
    });
    this.smartGoalsForm = this.formBuilder.group({
      smartGoals: new FormArray([

      ])
    });

    this.year = [];
    const date = new Date();
    const year = date.getFullYear();
    for (let i = year; i >= 2020; i--) {
      this.year.push({ label: i.toString(), value: i.toString() });
    }
  }

  get keyAcomps() {
    return this.basicDetailForm.get('keyAcomps') as FormArray;
  }

  get areaOfImprovments() {
    return this.areaOfImrovmentAndTraining.get('areaOfImprovments') as FormArray;
  }

  get smartGoals() {
    return this.smartGoalsForm.get('smartGoals') as FormArray;
  }

  addKeyAcomps() {
    const control = new FormControl('', Validators.required);
    if (this.keyAcomps.length < 5) {
      this.keyAcomps.push(control);
    }
  }

  addAreaOfImprovments() {
    const control = new FormControl('', Validators.required);
    if (this.areaOfImprovments.length < 5) {
      this.areaOfImprovments.push(control);
    }
  }

  addSmartGoals() {
    const control = new FormControl('', Validators.required);
    if (this.smartGoals.length < 5) {
      this.smartGoals.push(control);
    }
  }

  removeKeyAcomps(index: number) {
    this.keyAcomps.removeAt(index);
  }

  removeAreaOfImprovments(index: number) {
    this.areaOfImprovments.removeAt(index);
  }

  removeSmartGoals(index: number) {
    this.smartGoals.removeAt(index);
  }

  showDialog() {
    this.display = true;
  }

  yearChanged(event) {
    this.showLoader = true;
    this.filled_status = event.value != new Date().getFullYear();
    this.selectedYear = event.value;
    if (this.isSubmitted = true) {
      this.filled_status = true;
    }
    this.getEvaluationForm();
    this.index = -1;
    this.selected = false;
  }

  getEvaluationForm() {
    const params = this.route.snapshot.params;
    if (params && params.year) {
      this.selectedYear = params.year;
      this.formYear = params.year;
    }
    const { showSelf } = window.history.state || {};
    this.empId = params && params.EmpID ? params.EmpID : isAuth().emp_id;
    this.frontEndService.getEvaluationForm({ year: this.selectedYear, emp_id: this.empId }).subscribe((res) => {
      if (this.empId === isAuth().emp_id || showSelf) {
        this.formData = res.body.employee;
      } else {
        this.formData = res.body?.manager ||
        {
          EmpID: res.body.employee.EmpID,
          EmpName: res.body.employee.EmpName,
          Designation: res.body.employee.Designation,
          EmpDirectSupervisors: res.body.employee.EmpDirectSupervisors,
          EmpDepartments: res.body.employee.EmpDepartments,
          WorkLocationAndClient: res.body.employee.WorkLocationAndClient
        };
      }
      if (this.formData) {
        this.empName = params && params.EmpID ? this.formData.EmpName : 'Self';
        this.basicDetailForm.get('employeeName').setValue(this.formData.EmpName || '');
        this.basicDetailForm.get('position').setValue(this.formData.Designation || '');
        this.basicDetailForm.get('directSupervisor').setValue(this.formData.EmpDirectSupervisors || '');
        this.basicDetailForm.get('EmpDepartment').setValue(this.formData.EmpDepartments || '');
        this.basicDetailForm.get('workLocation').setValue(this.formData.WorkLocationAndClient || '');
        // this.basicDetailForm.patchValue({
        //   employeeName: this.formData.EmpName || '',
        //   position: this.formData.Designation || '',
        //   directSupervisor: this.formData.EmpDirectSupervisors || '',
        //   EmpDepartment: this.formData.EmpDepartments || [],
        //   workLocation: this.formData.WorkLocationAndClient || '',
        // });

        this.valueBasedKPI.patchValue({
          empathetic: this.formData.empathetic,
          empathetic_reason: this.formData.empathetic_reason,
          innovative: this.formData.innovative,
          innovative_reason: this.formData.innovative_reason,
          persevering: this.formData.persevering,
          persevering_reason: this.formData.persevering_reason,
          reliable: this.formData.reliable,
          reliable_reason: this.formData.reliable_reason,
          inclusive: this.formData.inclusive,
          inclusive_reason: this.formData.inclusive_reason,
        });

        this.performanceBasedKPI.patchValue({
          qualityOfWork: this.formData.quality_of_work,
          qualityOfWork_reason: this.formData.quality_of_work_reason,
          knowledgeOfJob: this.formData.knowledge_of_job,
          knowledgeOfJob_reason: this.formData.knowledge_of_job_reason,
          communication: this.formData.comm_inter_skills,
          communication_reason: this.formData.comm_inter_skills_reason,
          problemSolving: this.formData.prob_solving,
          problemSolving_reason: this.formData.prob_solving_reason,
          teamWorkAndCooperation: this.formData.teamwork_coop,
          teamWorkAndCooperation_reason: this.formData.teamwork_coop_reason,
          businessAcumen: this.formData.business_acumen,
          businessAcumen_reason: this.formData.business_acumen_reason,
          decisionMaking: this.formData.decision_making,
          decisionMaking_reason: this.formData.decision_making_reason,
        });
      }

      this.acompsArray = []
      if (this.formData) {
        if (this.formData.key_accomplishment1) {
          this.acompsArray.push(new FormControl(this.formData.key_accomplishment1, Validators.required))
        } else {
          this.acompsArray.push(new FormControl('', Validators.required))
        }
        if (this.formData.key_accomplishment2) {
          this.acompsArray.push(new FormControl(this.formData.key_accomplishment2, Validators.required))
        } else {
          this.acompsArray.push(new FormControl('', Validators.required))
        }
        if (this.formData.key_accomplishment3) {
          this.acompsArray.push(new FormControl(this.formData.key_accomplishment3, Validators.required))
        } else {
          this.acompsArray.push(new FormControl('', Validators.required))
        }
        if (this.formData.key_accomplishment4) {
          this.acompsArray.push(new FormControl(this.formData.key_accomplishment4, Validators.required))
        }
        if (this.formData.key_accomplishment5) {
          this.acompsArray.push(new FormControl(this.formData.key_accomplishment5, Validators.required))
        }
      } else {
        this.acompsArray.push(
          new FormControl('', Validators.required),
          new FormControl('', Validators.required),
          new FormControl('', Validators.required),
        )
      }
      if (this.formData) {
        this.basicDetailForm = this.formBuilder.group({
          employeeName: this.formData.EmpName || '',
          position: this.formData.Designation || '',
          directSupervisor: this.formData.EmpDirectSupervisors || '',
          EmpDepartment: this.formData.EmpDepartments || [],
          workLocation: this.formData.WorkLocationAndClient || '',
          keyAcomps: new FormArray(this.acompsArray)
        });
      }

      this.improvemntsArray = [];
      if (this.formData) {
        if (this.formData.area_improvement1) {
          this.improvemntsArray.push(new FormControl(this.formData.area_improvement1, Validators.required))
        } else {
          this.improvemntsArray.push(new FormControl('', Validators.required))
        }
        if (this.formData.area_improvement2) {
          this.improvemntsArray.push(new FormControl(this.formData.area_improvement2, Validators.required))
        } else {
          this.improvemntsArray.push(new FormControl('', Validators.required))
        }
        if (this.formData.area_improvement3) {
          this.improvemntsArray.push(new FormControl(this.formData.area_improvement3, Validators.required))
        } else {
          this.improvemntsArray.push(new FormControl('', Validators.required))
        }
        if (this.formData.area_improvement4) {
          this.improvemntsArray.push(new FormControl(this.formData.area_improvement4, Validators.required))
        }
        if (this.formData.area_improvement5) {
          this.improvemntsArray.push(new FormControl(this.formData.area_improvement5, Validators.required))
        }
      } else {
        this.improvemntsArray.push(
          new FormControl('', Validators.required),
          new FormControl('', Validators.required),
          new FormControl('', Validators.required),
        )
      }
      this.areaOfImrovmentAndTraining = this.formBuilder.group({
        areaOfImprovments: new FormArray(this.improvemntsArray),
        trainingNeeds: [{ value: '' }, Validators.required]
      });

      this.goalsArray = [];
      if (this.formData) {

        this.areaOfImrovmentAndTraining.patchValue({
          trainingNeeds: this.formData.training_dev,
        });

        if (this.formData.smart_goal1) {
          this.goalsArray.push(new FormControl(this.formData.smart_goal1, Validators.required))
        } else {
          this.goalsArray.push(new FormControl('', Validators.required))
        }
        if (this.formData.smart_goal2) {
          this.goalsArray.push(new FormControl(this.formData.smart_goal2, Validators.required))
        } else {
          this.goalsArray.push(new FormControl('', Validators.required))
        }
        if (this.formData.smart_goal3) {
          this.goalsArray.push(new FormControl(this.formData.smart_goal3, Validators.required))
        } else {
          this.goalsArray.push(new FormControl('', Validators.required))
        }
        if (this.formData.smart_goal4) {
          this.goalsArray.push(new FormControl(this.formData.smart_goal4, Validators.required))
        }
        if (this.formData.smart_goal5) {
          this.goalsArray.push(new FormControl(this.formData.smart_goal5, Validators.required))
        }
      } else {
        this.goalsArray.push(
          new FormControl('', Validators.required),
          new FormControl('', Validators.required),
          new FormControl('', Validators.required),
        )
      }
      this.smartGoalsForm = this.formBuilder.group({
        smartGoals: new FormArray(this.goalsArray)
      });
      if (this.formData && this.formData.status && this.formData.status === 'Submitted' && !this.isEditable) {
        _.map(this.acompsArray, control => control.disable());
        this.valueBasedKPI.get('empathetic').disable();
        this.valueBasedKPI.get('empathetic_reason').disable();
        this.valueBasedKPI.get('innovative').disable();
        this.valueBasedKPI.get('innovative_reason').disable();
        this.valueBasedKPI.get('persevering').disable();
        this.valueBasedKPI.get('persevering_reason').disable();
        this.valueBasedKPI.get('reliable').disable();
        this.valueBasedKPI.get('reliable_reason').disable();
        this.valueBasedKPI.get('inclusive').disable();
        this.valueBasedKPI.get('inclusive_reason').disable();
        this.performanceBasedKPI.get('qualityOfWork').disable();
        this.performanceBasedKPI.get('qualityOfWork_reason').disable();
        this.performanceBasedKPI.get('knowledgeOfJob').disable();
        this.performanceBasedKPI.get('knowledgeOfJob_reason').disable();
        this.performanceBasedKPI.get('communication').disable();
        this.performanceBasedKPI.get('communication_reason').disable();
        this.performanceBasedKPI.get('problemSolving').disable();
        this.performanceBasedKPI.get('problemSolving_reason').disable();
        this.performanceBasedKPI.get('teamWorkAndCooperation').disable();
        this.performanceBasedKPI.get('teamWorkAndCooperation_reason').disable();
        this.performanceBasedKPI.get('businessAcumen').disable();
        this.performanceBasedKPI.get('businessAcumen_reason').disable();
        this.performanceBasedKPI.get('decisionMaking').disable();
        this.performanceBasedKPI.get('decisionMaking_reason').disable();
        setTimeout(() => {
          this.areaOfImrovmentAndTraining.get('trainingNeeds').disable();
        }, 0);
        _.map(this.improvemntsArray, control => control.disable());
        _.map(this.goalsArray, control => control.disable());
        this.isSubmitted = true;
      } else {
        this.isSubmitted = false;
        _.map(this.acompsArray, control => control.enable());
        this.valueBasedKPI.get('empathetic').enable();
        this.valueBasedKPI.get('empathetic_reason').enable();
        this.valueBasedKPI.get('innovative').enable();
        this.valueBasedKPI.get('innovative_reason').enable();
        this.valueBasedKPI.get('persevering').enable();
        this.valueBasedKPI.get('persevering_reason').enable();
        this.valueBasedKPI.get('reliable').enable();
        this.valueBasedKPI.get('reliable_reason').enable();
        this.valueBasedKPI.get('inclusive').enable();
        this.valueBasedKPI.get('inclusive_reason').enable();
        this.performanceBasedKPI.get('qualityOfWork').enable();
        this.performanceBasedKPI.get('qualityOfWork_reason').enable();
        this.performanceBasedKPI.get('knowledgeOfJob').enable();
        this.performanceBasedKPI.get('knowledgeOfJob_reason').enable();
        this.performanceBasedKPI.get('communication').enable();
        this.performanceBasedKPI.get('communication_reason').enable();
        this.performanceBasedKPI.get('problemSolving').enable();
        this.performanceBasedKPI.get('problemSolving_reason').enable();
        this.performanceBasedKPI.get('teamWorkAndCooperation').enable();
        this.performanceBasedKPI.get('teamWorkAndCooperation_reason').enable();
        this.performanceBasedKPI.get('businessAcumen').enable();
        this.performanceBasedKPI.get('businessAcumen_reason').enable();
        this.performanceBasedKPI.get('decisionMaking').enable();
        this.performanceBasedKPI.get('decisionMaking_reason').enable();
        setTimeout(() => {
          this.areaOfImrovmentAndTraining.get('trainingNeeds').enable();
        }, 0);
        _.map(this.improvemntsArray, control => control.enable());
        _.map(this.goalsArray, control => control.enable());
      }
      this.showLoader = false;
      this.onReasonChange('');
    });
  }

  getAreaOfImprovements() {
    this.areaOfImrovmentAndTraining.get('areaOfImprovment') as FormArray
  }

  getEvaluationOptions() {
    this.frontEndService.getEvaluationOptions().subscribe((res) => {
      this.evaluationOptions = res.body.evaluation_options;
      this.evaluationOptions.unshift({ value: null, label: 'Choose' });
      this.getEvaluationForm();
    });
  }

  saveBasicDetailForm() {
    const data = {
      year: this.selectedYear,
      emp_id: this.empId,
      section: "basic",
      submitted_by: isAuth().emp_id,
      key_accomplishment1: this.basicDetailForm.get('keyAcomps').value[0],
      key_accomplishment2: this.basicDetailForm.get('keyAcomps').value[1],
      key_accomplishment3: this.basicDetailForm.get('keyAcomps').value[2],
      key_accomplishment4: this.basicDetailForm.get('keyAcomps').value[3],
      key_accomplishment5: this.basicDetailForm.get('keyAcomps').value[4],
    }
    this.frontEndService.saveFormEvaluationForm(data).subscribe((res) => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Basic detail submitted' });
      this.formSections[0] = true;
    });
  }

  saveValueBasedKPIFrom() {
    const data = {
      year: this.selectedYear,
      emp_id: this.empId,
      section: "values-based",
      submitted_by: isAuth().emp_id,
      empathetic: this.valueBasedKPI.get('empathetic').value,
      empathetic_reason: this.valueBasedKPI.get('empathetic_reason').value,
      innovative: this.valueBasedKPI.get('innovative').value,
      innovative_reason: this.valueBasedKPI.get('innovative_reason').value,
      persevering: this.valueBasedKPI.get('persevering').value,
      persevering_reason: this.valueBasedKPI.get('persevering_reason').value,
      reliable: this.valueBasedKPI.get('reliable').value,
      reliable_reason: this.valueBasedKPI.get('reliable_reason').value,
      inclusive: this.valueBasedKPI.get('inclusive').value,
      inclusive_reason: this.valueBasedKPI.get('inclusive_reason').value
    }
    this.frontEndService.saveFormEvaluationForm(data).subscribe((res) => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Value based KPIs submitted' });
      this.formSections[1] = true;
    });
  }

  savePerfBasedKPISForm() {
    const data = {
      year: this.selectedYear,
      emp_id: this.empId,
      section: "perf-based",
      submitted_by: isAuth().emp_id,
      quality_of_work: this.performanceBasedKPI.get('qualityOfWork').value,
      quality_of_work_reason: this.performanceBasedKPI.get('qualityOfWork_reason').value,
      knowledge_of_job: this.performanceBasedKPI.get('knowledgeOfJob').value,
      knowledge_of_job_reason: this.performanceBasedKPI.get('knowledgeOfJob_reason').value,
      comm_inter_skills: this.performanceBasedKPI.get('communication').value,
      comm_inter_skills_reason: this.performanceBasedKPI.get('communication_reason').value,
      prob_solving: this.performanceBasedKPI.get('problemSolving').value,
      prob_solving_reason: this.performanceBasedKPI.get('problemSolving_reason').value,
      teamwork_coop: this.performanceBasedKPI.get('teamWorkAndCooperation').value,
      teamwork_coop_reason: this.performanceBasedKPI.get('teamWorkAndCooperation_reason').value,
      business_acumen: this.performanceBasedKPI.get('businessAcumen').value,
      business_acumen_reason: this.performanceBasedKPI.get('businessAcumen_reason').value,
      decision_making: this.performanceBasedKPI.get('decisionMaking').value,
      decision_making_reason: this.performanceBasedKPI.get('decisionMaking_reason').value
    }
    this.frontEndService.saveFormEvaluationForm(data).subscribe((res) => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Performance based KPIs submitted' });
      this.formSections[2] = true;
    });
  }

  saveAreaOfImpAndTrainingForm() {
    const data = {
      year: this.selectedYear,
      emp_id: this.empId,
      section: "improve-training",
      submitted_by: isAuth().emp_id,
      area_improvement1: this.areaOfImrovmentAndTraining.get('areaOfImprovments').value[0],
      area_improvement2: this.areaOfImrovmentAndTraining.get('areaOfImprovments').value[1],
      area_improvement3: this.areaOfImrovmentAndTraining.get('areaOfImprovments').value[2],
      area_improvement4: this.areaOfImrovmentAndTraining.get('areaOfImprovments').value[3],
      area_improvement5: this.areaOfImrovmentAndTraining.get('areaOfImprovments').value[4],
      training_dev: this.areaOfImrovmentAndTraining.get('trainingNeeds').value
    }

    this.frontEndService.saveFormEvaluationForm(data).subscribe((res) => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Area of improvment submitted' });
      this.formSections[3] = true;
    });
  }

  saveSMARTGoalsForm() {
    const data = {
      year: this.selectedYear,
      emp_id: this.empId,
      section: "smart",
      submitted_by: isAuth().emp_id,
      smart_goal1: this.smartGoalsForm.get('smartGoals').value[0],
      smart_goal2: this.smartGoalsForm.get('smartGoals').value[1],
      smart_goal3: this.smartGoalsForm.get('smartGoals').value[2],
      smart_goal4: this.smartGoalsForm.get('smartGoals').value[3],
      smart_goal5: this.smartGoalsForm.get('smartGoals').value[4],
    }
    this.frontEndService.saveFormEvaluationForm(data).subscribe((res) => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'SMART goals submitted' });
      this.formSections[4] = true;
    });
  }

  onFormSubmit() {
    const formState = 'VALID'
    if (this.basicDetailForm.status === formState && this.valueBasedKPI.status === formState && this.performanceBasedKPI.status === formState && this.areaOfImrovmentAndTraining.status === formState && this.smartGoalsForm.status === formState) {
      this.filled_status = false;
    }
    this.singleFormSubmitted = true;
  }

  submitEvaluationForm() {
    const data = {
      year: this.selectedYear,
      emp_id: this.empId,
      submitted_by: isAuth().emp_id,
      key_accomplishment1: this.basicDetailForm.get('keyAcomps').value[0],
      key_accomplishment2: this.basicDetailForm.get('keyAcomps').value[1],
      key_accomplishment3: this.basicDetailForm.get('keyAcomps').value[2],
      key_accomplishment4: this.basicDetailForm.get('keyAcomps').value[3],
      key_accomplishment5: this.basicDetailForm.get('keyAcomps').value[4],
      empathetic: this.valueBasedKPI.get('empathetic').value,
      empathetic_reason: this.valueBasedKPI.get('empathetic_reason').value,
      innovative: this.valueBasedKPI.get('innovative').value,
      innovative_reason: this.valueBasedKPI.get('innovative_reason').value,
      persevering: this.valueBasedKPI.get('persevering').value,
      persevering_reason: this.valueBasedKPI.get('persevering_reason').value,
      reliable: this.valueBasedKPI.get('reliable').value,
      reliable_reason: this.valueBasedKPI.get('reliable_reason').value,
      inclusive: this.valueBasedKPI.get('inclusive').value,
      inclusive_reason: this.valueBasedKPI.get('inclusive_reason').value,
      quality_of_work: this.performanceBasedKPI.get('qualityOfWork').value,
      quality_of_work_reason: this.performanceBasedKPI.get('qualityOfWork_reason').value,
      knowledge_of_job: this.performanceBasedKPI.get('knowledgeOfJob').value,
      knowledge_of_job_reason: this.performanceBasedKPI.get('knowledgeOfJob_reason').value,
      comm_inter_skills: this.performanceBasedKPI.get('communication').value,
      comm_inter_skills_reason: this.performanceBasedKPI.get('communication_reason').value,
      prob_solving: this.performanceBasedKPI.get('problemSolving').value,
      prob_solving_reason: this.performanceBasedKPI.get('problemSolving_reason').value,
      teamwork_coop: this.performanceBasedKPI.get('teamWorkAndCooperation').value,
      teamwork_coop_reason: this.performanceBasedKPI.get('teamWorkAndCooperation_reason').value,
      business_acumen: this.performanceBasedKPI.get('businessAcumen').value,
      business_acumen_reason: this.performanceBasedKPI.get('businessAcumen_reason').value,
      decision_making: this.performanceBasedKPI.get('decisionMaking').value,
      decision_making_reason: this.performanceBasedKPI.get('decisionMaking_reason').value,
      area_improvement1: this.areaOfImrovmentAndTraining.get('areaOfImprovments').value[0],
      area_improvement2: this.areaOfImrovmentAndTraining.get('areaOfImprovments').value[1],
      area_improvement3: this.areaOfImrovmentAndTraining.get('areaOfImprovments').value[2],
      area_improvement4: this.areaOfImrovmentAndTraining.get('areaOfImprovments').value[3],
      area_improvement5: this.areaOfImrovmentAndTraining.get('areaOfImprovments').value[4],
      training_dev: this.areaOfImrovmentAndTraining.get('trainingNeeds').value,
      smart_goal1: this.smartGoalsForm.get('smartGoals').value[0],
      smart_goal2: this.smartGoalsForm.get('smartGoals').value[1],
      smart_goal3: this.smartGoalsForm.get('smartGoals').value[2],
      smart_goal4: this.smartGoalsForm.get('smartGoals').value[3],
      smart_goal5: this.smartGoalsForm.get('smartGoals').value[4],
      status: "Submitted"
    }
    this.filled_status = true;
    this.showLoader = true;
    this.frontEndService.submitEvaluationForm(data).subscribe((res) => {
      this.showLoader = false;
      this.message = "Form has been successfully submitted";
      this.isSubmitted = true;
      if (this.isSubmitted) {
        _.map(this.acompsArray, control => control.disable());
        this.valueBasedKPI.get('empathetic').disable();
        this.valueBasedKPI.get('empathetic_reason').disable();
        this.valueBasedKPI.get('innovative').disable();
        this.valueBasedKPI.get('innovative_reason').disable();
        this.valueBasedKPI.get('persevering').disable();
        this.valueBasedKPI.get('persevering_reason').disable();
        this.valueBasedKPI.get('reliable').disable();
        this.valueBasedKPI.get('reliable_reason').disable();
        this.valueBasedKPI.get('inclusive').disable();
        this.valueBasedKPI.get('inclusive_reason').disable();
        this.performanceBasedKPI.get('qualityOfWork').disable();
        this.performanceBasedKPI.get('qualityOfWork_reason').disable();
        this.performanceBasedKPI.get('knowledgeOfJob').disable();
        this.performanceBasedKPI.get('knowledgeOfJob_reason').disable();
        this.performanceBasedKPI.get('communication').disable();
        this.performanceBasedKPI.get('communication_reason').disable();
        this.performanceBasedKPI.get('problemSolving').disable();
        this.performanceBasedKPI.get('problemSolving_reason').disable();
        this.performanceBasedKPI.get('teamWorkAndCooperation').disable();
        this.performanceBasedKPI.get('teamWorkAndCooperation_reason').disable();
        this.performanceBasedKPI.get('businessAcumen').disable();
        this.performanceBasedKPI.get('businessAcumen_reason').disable();
        this.performanceBasedKPI.get('decisionMaking').disable();
        this.performanceBasedKPI.get('decisionMaking_reason').disable();
        _.map(this.improvemntsArray, control => control.disable());
        setTimeout(() => {
          this.areaOfImrovmentAndTraining.get('trainingNeeds').disable();
        }, 0);
        _.map(this.goalsArray, control => control.disable());
      }
    });
  }

  getDepartments() {
    this.frontEndService.getDepartments().subscribe((res) => {
      const departments = res.body.departments;
      this.departments = departments.map(dep => {
        return {
          label: dep.label,
          value: dep.label
        }
      })
      this.departments.unshift({ label: 'All Departments', value: null });
    });
  }

  checkDisableSubmit(): boolean {
    if (this.isSubmitted) {
      return this.isSubmitted;
    } else {
      return !(!this.basicDetailForm.invalid && !this.valueBasedKPI.invalid && !this.performanceBasedKPI.invalid
        && !this.areaOfImrovmentAndTraining.invalid && !this.smartGoalsForm.invalid);
    }

  }

  onReasonChange($event) {
    // TODO: @Waqas Need to refactor so that it's dynamic for all fields
    if (
      this.valueBasedKPI.get('empathetic').value === 4 ||
      this.valueBasedKPI.get('empathetic').value === 5) 
    {
      this.empatheticReason = true;
    } else {
      this.empatheticReason = false;
      this.valueBasedKPI.get('empathetic_reason').setValue('');
    }

    if (
      this.valueBasedKPI.get('innovative').value === 4 ||
      this.valueBasedKPI.get('innovative').value === 5
    ) {
      this.innovativeReason = true;
    } else {
      this.innovativeReason = false;
      this.valueBasedKPI.get('innovative_reason').setValue('');
    }

    if (
      this.valueBasedKPI.get('persevering').value === 4 ||
      this.valueBasedKPI.get('persevering').value === 5
    ) {
      this.perseveringReason = true;
    } else {
      this.perseveringReason = false;
      this.valueBasedKPI.get('persevering_reason').setValue('');
    }

    if (
      this.valueBasedKPI.get('reliable').value === 4 ||
      this.valueBasedKPI.get('reliable').value === 5
    ) {
      this.reliableReason = true;
    } else {
      this.reliableReason = false;
      this.valueBasedKPI.get('reliable_reason').setValue('');
    }
    
    if (
      this.valueBasedKPI.get('inclusive').value === 4 ||
      this.valueBasedKPI.get('inclusive').value === 5
    ) {
      this.inclusiveReason = true;
    } else {
      this.inclusiveReason = false;
      this.valueBasedKPI.get('inclusive_reason').setValue('');
    }
  
    if (
      this.performanceBasedKPI.get('qualityOfWork').value === 4 ||
      this.performanceBasedKPI.get('qualityOfWork').value === 5
    ) {
      this.qualityOfWorkReason = true;
    } else {
      this.qualityOfWorkReason = false;
      this.performanceBasedKPI.get('qualityOfWork_reason').setValue('');
    }

    if (
      this.performanceBasedKPI.get('knowledgeOfJob').value === 4 ||
      this.performanceBasedKPI.get('knowledgeOfJob').value === 5
    ) {
      this.knowledgeOfJobReason = true;
    } else {
      this.knowledgeOfJobReason = false;
      this.performanceBasedKPI.get('knowledgeOfJob_reason').setValue('');
    }
    
    if (
      this.performanceBasedKPI.get('communication').value === 4 ||
      this.performanceBasedKPI.get('communication').value === 5
    ) {
      this.communicationReason = true;
    } else {
      this.communicationReason = false;
      this.performanceBasedKPI.get('communication_reason').setValue('');
    }

    if (
      this.performanceBasedKPI.get('problemSolving').value === 4 ||
      this.performanceBasedKPI.get('problemSolving').value === 5
    ) {
      this.problemSolvingReason = true;
    } else {
      this.problemSolvingReason = false;
      this.performanceBasedKPI.get('problemSolving_reason').setValue('');
    }

    if (
      this.performanceBasedKPI.get('teamWorkAndCooperation').value === 4 ||
      this.performanceBasedKPI.get('teamWorkAndCooperation').value === 5
    ) {
      this.teamworkandCooperationReason = true;
    } else {
      this.teamworkandCooperationReason = false;
      this.performanceBasedKPI.get('teamWorkAndCooperation_reason').setValue('');
    }

    if (
      this.performanceBasedKPI.get('businessAcumen').value === 4 ||
      this.performanceBasedKPI.get('businessAcumen').value === 5
    ) {
      this.businessAcumenReason = true;
    } else {
      this.businessAcumenReason = false;
      this.performanceBasedKPI.get('businessAcumen_reason').setValue('');
    }

    if (
      this.performanceBasedKPI.get('decisionMaking').value === 4 ||
      this.performanceBasedKPI.get('decisionMaking').value === 5
    ) {
      this.decisionMakingReason = true;
    } else {
      this.decisionMakingReason = false;
      this.performanceBasedKPI.get('decisionMaking_reason').setValue('');
    }
    
  }

}
