
import { NgModule } from '@angular/core';
import { AdminRoutingModule } from './admin-routing';

import { AdminComponent } from './admin.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from '../includes/header/header.component';
import { FooterComponent } from '../includes/footer/footer.component';
import { RegisterComponent } from '../register/register.component';
import { AllEmployeeListComponent } from './all-employee-list/all-employee-list.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { EvaluationFormComponent } from './evaluation-form/evaluation-form.component';
import { UnderSupervisionComponent } from './under-supervision/under-supervision.component';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { ChartModule } from 'primeng/chart';
import { PiChartComponent } from './pi-chart/pi-chart.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { LineChartComponent } from './line-chart/line-chart.component';
import { PolarAreaChartComponent } from './polar-area-chart/polar-area-chart.component';
import { AccordionModule } from 'primeng/accordion';
import { MultiSelectModule } from 'primeng/multiselect';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JWTInterceptor } from 'src/app/auth/jwt.interceptor';
import { ReactiveFormsModule } from '@angular/forms';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  declarations: [
    AdminComponent,
    DashboardComponent,
    HeaderComponent,
    FooterComponent,
    RegisterComponent,
    AllEmployeeListComponent,
    EditUserComponent,
    PiChartComponent,
    BarChartComponent,
    LineChartComponent,
    PolarAreaChartComponent,
    EvaluationFormComponent,
    UnderSupervisionComponent
  ],
  imports: [
    AdminRoutingModule,
    FormsModule,
    TableModule,
    DropdownModule,
    ChartModule,
    AccordionModule,
    MultiSelectModule,
    DialogModule,
    ConfirmDialogModule,
    CommonModule,
    ReactiveFormsModule,
    ToastModule,
    TooltipModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JWTInterceptor,
      multi: true
    },
  ]
})
export class AdminModule { }
