<div class="header">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="header-holder">
          <strong class="logo">
            <img src="assets/images/logo.png" alt="Mobile Live">
          </strong>
          <nav>
            <ul>
              <li>
                <strong>{{ user.name }}</strong>
                <span>Employee ID: <span>{{ user.emp_no }}</span></span>
              </li>
              <li><a (click)="signOut()" routerLinkActive="router-link-active" >Logout</a></li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</div>
