<div class="card-box">
  <div class="head">
    <strong class="logo">
      <a [routerLink]="['/login']" routerLinkActive="router-link-active" >
        <img src="../../../mpes/assets/images/logo-white.svg" alt="Mobilelive">
      </a>
    </strong>
    <h2>Performence Evaluation System</h2>
  </div>
  <div class="box">
    <div class="left-align"></div>
    <div class="right-align">
      <div class="form-holder">
        <h2>Forgot Password?</h2>
        <form>
          <div class="form-group">
            <label>Enter Your Email</label>
            <input type="email" class="form-control">
          </div>
          <div class="form-group">
            <button class="btn btn-primary">Submit</button>
          </div>
        </form>
        <span class="forget-password"><a [routerLink]="['/login']">Back To Login</a></span>
      </div>
    </div>
  </div>
</div>
